import { ReactElement } from 'react'
import { Typography, Button, message } from 'antd'
import { PartnerQuote } from '../../models/manager/partner'

interface Props {
  quote: PartnerQuote
}

export function QuotePreview({ quote }: Props): ReactElement {
  const handleCopyContent = () => {
    const content = `
Quote Reference: ${quote.ReferenceId}
Name: ${quote.Name}
Description: ${quote.Description || '-'}

Quote Breakdown:
Event Activation Fee: $250.00
Badge Cost (${quote.BadgeCount} @ $0.75 each): $${(quote.BadgeCount * 0.75).toFixed(2)}
Print Agent License Fee (${quote.PrintAgentCount} @ $50.00 each): $${(quote.PrintAgentCount * 50).toFixed(2)}

${quote.PartnerLineItems?.map((item) => `${item.Name}: $${item.Amount.toFixed(2)}`).join('\n') || ''}

Total: $${calculateTotal(quote).toFixed(2)}
    `.trim()

    navigator.clipboard.writeText(content)
    message.success('Quote content copied to clipboard')
  }

  const calculateTotal = (quoteData: PartnerQuote): number => {
    const badgeCost = quoteData.BadgeCount * 0.75
    const printAgentCost = quoteData.PrintAgentCount * 50
    const activationFee = 250
    const lineItemsTotal = quoteData.PartnerLineItems?.reduce((sum, item) => sum + item.Amount, 0) || 0
    return badgeCost + printAgentCost + activationFee + lineItemsTotal
  }

  const calculateDiscountAmount = (quoteData: PartnerQuote): number => {
    if (!quoteData.PartnerDiscount) return 0
    return (calculateTotal(quoteData) * quoteData.PartnerDiscount.DiscountPercentage) / 100
  }

  const calculateFinalTotal = (quoteData: PartnerQuote): number => {
    const total = calculateTotal(quoteData)
    const discount = calculateDiscountAmount(quoteData)
    return total - discount
  }

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg">
        <div className="mb-6">
          <Typography.Title level={4} className="mb-2">
            {quote.Name}
          </Typography.Title>
          <Typography.Text type="secondary">Reference ID: {quote.ReferenceId}</Typography.Text>
          {quote.Description && <Typography.Paragraph className="mt-2">{quote.Description}</Typography.Paragraph>}
        </div>

        <Typography.Title level={5} className="mb-4">
          Quote Breakdown
        </Typography.Title>

        <div className="space-y-4">
          {/* Base Costs */}
          <div className="border rounded-lg p-4">
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Event Activation Fee:</span>
                <span className="font-medium">$250.00</span>
              </div>
              <div className="flex justify-between">
                <span>Badge Cost ({quote.BadgeCount} @ $0.75 each):</span>
                <span className="font-medium">${(quote.BadgeCount * 0.75).toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span>Print Agent License Fee ({quote.PrintAgentCount} @ $50.00 each):</span>
                <span className="font-medium">${(quote.PrintAgentCount * 50).toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Partner Line Items */}
          {quote.PartnerLineItems && quote.PartnerLineItems.length > 0 && (
            <div className="border rounded-lg p-4">
              <Typography.Text strong className="block mb-2">
                Additional Items
              </Typography.Text>
              <div className="space-y-2">
                {quote.PartnerLineItems.map((item, index) => (
                  <div key={index} className="flex justify-between">
                    <span>{item.Name}:</span>
                    <span className="font-medium">${item.Amount.toFixed(2)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Subtotal */}
          <div className="border rounded-lg p-4">
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Subtotal:</span>
                <span className="font-medium">${calculateTotal(quote).toFixed(2)}</span>
              </div>

              {/* Partner Discount */}
              {quote.PartnerDiscount && (
                <div className="flex justify-between text-green-600">
                  <span>Partner Discount ({quote.PartnerDiscount.DiscountPercentage}%):</span>
                  <span className="font-medium">-${calculateDiscountAmount(quote).toFixed(2)}</span>
                </div>
              )}

              {/* Final Total */}
              <div className="flex justify-between font-bold text-lg pt-2 border-t">
                <span>Total:</span>
                <span>${calculateFinalTotal(quote).toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button className="copy-content-button hidden" onClick={handleCopyContent} />
    </div>
  )
}
