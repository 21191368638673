import { ReactElement, useEffect, useState } from 'react'
import { Table, Card, Button, Space, message, Modal, Tooltip, Typography } from 'antd'
import type { SortOrder } from 'antd/lib/table/interface'
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled, EyeOutlined, CopyOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchPartnerQuotes, deletePartnerQuote } from '../../services/partners'
import { PartnerQuote } from '../../models/manager/partner'
import { useProfileContext } from '../../contexts/profileContext'
import { useNavigate } from 'react-router-dom'
import { errorHandler } from '../../utils/helper'
import { SiteButton } from '../../components/controls/SiteButton'
import { PartnerLayout } from '../../components/layout/PartnerLayout'
import { QuotePreview } from '../../components/partners/QuotePreview'

export default function PartnerQuotes(): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [quotes, setQuotes] = useState<PartnerQuote[]>([])
  const [previewQuote, setPreviewQuote] = useState<PartnerQuote | null>(null)

  useEffect(() => {
    loadQuotes()
  }, [])

  const loadQuotes = async () => {
    try {
      setLoading(true)
      const token = await getAccessTokenSilently()
      const quotesData = await fetchPartnerQuotes(profile.AccountId, token)
      setQuotes(quotesData)
    } catch (error) {
      message.error('Failed to load quotes')
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const goToCreateQuotePage = () => {
    navigate('/partners/quotes/create')
  }

  const handlePreview = (quote: PartnerQuote) => {
    setPreviewQuote(quote)
  }

  const handleClosePreview = () => {
    setPreviewQuote(null)
  }

  const columns = [
    {
      title: 'Reference ID',
      dataIndex: 'ReferenceId',
      key: 'ReferenceId',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text: string) => text || '-',
    },
    {
      title: 'Badge Count',
      dataIndex: 'BadgeCount',
      key: 'BadgeCount',
    },
    {
      title: 'Print Agent Count',
      dataIndex: 'PrintAgentCount',
      key: 'PrintAgentCount',
    },
    {
      title: 'Last Updated',
      dataIndex: 'UpdatedAt',
      key: 'UpdatedAt',
      render: (date: string) => {
        const d = new Date(date)
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}`
      },
      defaultSortOrder: 'ascend' as SortOrder,
      sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
      sorter: (a: PartnerQuote, b: PartnerQuote) => new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: PartnerQuote) => (
        <Space>
          <Tooltip title="Preview">
            <Button type="link" icon={<EyeOutlined />} onClick={() => handlePreview(record)} />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => navigate(`/partners/quotes/${record.ReferenceId}`)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record.Id)} />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const handleDelete = (quoteId: number) => {
    const { confirm } = Modal
    confirm({
      title: 'Are you sure you want to delete this quote?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          const token = await getAccessTokenSilently()
          await deletePartnerQuote(quoteId, token)
          message.success('Quote deleted successfully')
          loadQuotes()
        } catch (error) {
          message.error('Failed to delete quote')
          errorHandler(error)
        }
      },
    })
  }

  return (
    <PartnerLayout>
      <div className="p-8 pt-4 bg-gray-50 min-h-screen">
        <div className="mb-6">
          <Typography.Title level={2} className="mb-4">
            Partner Quotes
          </Typography.Title>
          <Card>
            <div className="flex justify-between items-center mb-6">
              <div></div>
              <SiteButton label="Create New Quote" id="createQuote" onClick={goToCreateQuotePage} />
            </div>
            <Table loading={loading} columns={columns} dataSource={quotes} rowKey="Id" pagination={{ pageSize: 10 }} />
          </Card>
        </div>
      </div>
      <Modal
        title="Quote Preview"
        open={previewQuote !== null}
        onCancel={handleClosePreview}
        footer={[
          <Button
            key="copy"
            type="primary"
            icon={<CopyOutlined />}
            onClick={() => previewQuote && document.querySelector<HTMLButtonElement>('.copy-content-button')?.click()}
          >
            Copy Content
          </Button>,
          <Button key="close" onClick={handleClosePreview}>
            Close
          </Button>,
        ]}
        width={800}
      >
        {previewQuote && <QuotePreview quote={previewQuote} />}
      </Modal>
    </PartnerLayout>
  )
}
