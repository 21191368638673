import { lazy, ReactElement, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
const EventDashboard = lazy(() => import('../pages/events/EventDashboard'))
const EventAdmin = lazy(() => import('../pages/events/EventAdmin'))
const EventKiosk = lazy(() => import('../pages/events/EventKiosk'))
const EventReports = lazy(() => import('../pages/events/EventReports'))
const EventIntegrations = lazy(() => import('../pages/events/EventIntegrations'))
const EventBadges = lazy(() => import('../pages/events/EventBadges'))
const EventPrinters = lazy(() => import('../pages/events/EventPrinters'))
const EventRegTypes = lazy(() => import('../pages/events/EventRegTypes'))
const EventData = lazy(() => import('../pages/events/EventData'))
const EventHelp = lazy(() => import('../pages/events/EventHelp'))
const GettingStarted = lazy(() => import('../pages/events/guide/GettingStarted'))
const PrinterManagement = lazy(() => import('../pages/events/guide/PrinterManagement'))
const KioskSetup = lazy(() => import('../pages/events/guide/KioskSetup'))
const AdminTools = lazy(() => import('../pages/events/guide/AdminTools'))
const BadgeDesign = lazy(() => import('../pages/events/guide/BadgeDesign'))
const RegistrationTypes = lazy(() => import('../pages/events/guide/RegistrationTypes'))

const ZapierIntegration = lazy(() => import('../pages/events/integrations/ZapierIntegration'))
const MtileyIntegration = lazy(() => import('../pages/events/integrations/MtileyIntegration'))

export const EventsRoutes = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/events/dashboard" />} />
        <Route path="/dashboard" element={<EventDashboard />} />
        <Route path="/admin" element={<EventAdmin />} />
        <Route path="/kiosk" element={<EventKiosk />} />
        <Route path="/reports" element={<EventReports />} />
        <Route path="/event-data" element={<EventData />} />
        <Route path="/integrations" element={<EventIntegrations />} />
        <Route path="/badges" element={<EventBadges />} />
        <Route path="/reg-types" element={<EventRegTypes />} />
        <Route path="/printers" element={<EventPrinters />} />
        <Route path="/guide" element={<EventHelp />} />
        <Route path="/guide/getting-started" element={<GettingStarted />} />
        <Route path="/guide/printer-management" element={<PrinterManagement />} />
        <Route path="/guide/kiosk-setup" element={<KioskSetup />} />
        <Route path="/guide/admin-tools" element={<AdminTools />} />
        <Route path="/guide/badge-design" element={<BadgeDesign />} />
        <Route path="/guide/registration-types" element={<RegistrationTypes />} />
        <Route path="/zapier-integration" element={<ZapierIntegration />} />
        <Route path="/mtiley-integration" element={<MtileyIntegration />} />
      </Routes>
    </Suspense>
  )
}
