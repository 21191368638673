import { Status, FieldType, UserStatusEnum, FREE_TRIAL_DAYS } from '../constants/common'
import QRCode from 'qrcode'
import * as svg from 'polotno/utils/svg'
import {
  TemplateTypeEnum,
  SHIPPING_CUTOFF_LOCALE,
  SHIPPING_CUTOFF_TIME_EST,
  SHIPPING_TZ_LOCALE,
  TEMPLATE_GUIDELINE_DIMENSIONS,
} from '../constants/common'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { TemplateType } from '../models/manager/template'
import { FieldSet } from '../models/manager/fieldset'
import Papa from 'papaparse'

export function errorHandler(error: unknown): void {
  // Type guard to check if error is an object with response property
  if (
    error &&
    typeof error === 'object' &&
    'response' in error &&
    error.response &&
    typeof error.response === 'object' &&
    'status' in error.response
  ) {
    if (error.response.status === 404) return
  }

  console.error(error) // eslint-disable-line no-console
}

export function statusColor(statusId: number): Record<string, string> {
  switch (statusId) {
    case Status.Draft:
      return { backgroundColor: 'var(--blue-1, #E6F4FF)', color: '#1D8EF1', border: '1px solid var(--blue-3, #91CAFF)' }
    case Status.InProgress:
      return {
        backgroundColor: 'var(--cyan-1, #E6FFFB)',
        color: '#13C2C2',
        border: ' 1px solid var(--cyan-6, #13C2C2)',
      }
    case Status.Printing:
      return {
        backgroundColor: 'var(--orange-1, #FFF7E6)',
        color: '#FA8C16',
        border: '1px solid var(--orange-3, #FFD591)',
      }
    case Status.Printed:
      return {
        backgroundColor: 'var(--green-1, #F6FFED)',
        color: '#52C41A',
        border: '1px solid var(--green-3, #B7EB8F)',
      }
    case Status.Shipped:
      return {
        backgroundColor: 'var(--purple-1, #F9F0FF)',
        color: '#722ED1',
        border: '1px solid var(--purple-3, #D3ADF7)',
      }
    case Status.Received:
      return {
        backgroundColor: 'var(--geekblue-1, #F0F5FF)',
        color: '#2F54EB',
        border: ' 1px solid var(--geekblue-3, #ADC6FF)',
      }
    case Status.Cancelled:
      return {
        backgroundColor: 'var(--red-1, #FFF1F0)',
        color: '#F5222D',
        border: '1px solid var(--red-3, #FFA39E)',
      }
  }

  return {}
}

export function userStatusColor(UserStatusId: number): Record<string, string> {
  switch (UserStatusId) {
    case UserStatusEnum.Invited:
      return { backgroundColor: '#E6FFFB', color: '#13C2C2', border: '1px solid #13C2C2' }
    case UserStatusEnum.Active:
      return { backgroundColor: '#F6FFED', color: '#52C41A', border: '1px solid #52C41A' }
    case UserStatusEnum.Disabled:
      return { backgroundColor: '#FFF7E6', color: '#FA8C16', border: '1px solid #FA8C16' }
  }

  return {}
}

export function statusFieldType(FieldTypeId: FieldType): string {
  switch (FieldTypeId) {
    case FieldType.QRCode:
      return 'QR Code'
    case FieldType.Code128:
      return 'Code128'
    case FieldType.Code39:
      return 'Code39'
    case FieldType.EAN8:
      return 'EAN8'
    case FieldType.EAN16:
      return 'EAN16'
    case FieldType.UPC:
      return 'UPC'
  }

  return ''
}

export async function getQr(text: string): Promise<string> {
  return new Promise((resolve) => {
    QRCode.toString(
      text || 'no-data',
      {
        type: 'svg',
        color: {
          dark: '#000',
          light: '#0000', // Transparent background
        },
      },
      (_err, string) => {
        resolve(svg.svgToURL(string))
      }
    )
  })
}

export function getFileExtension(filename: string): string {
  const parts = filename.split('.')
  if (parts.length > 1) {
    return parts[parts.length - 1]
  } else {
    return '' // No extension found
  }
}

export function insertSpaceCamelCase(str = ''): string {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function insertSpaceCamelCaseAndNumbers(str: string): string {
  return str.replace(/([a-z,0-9])([A-Z,0-9])/g, '$1 $2')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getCardType(store: any): any {
  if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.dimensions.h
  ) {
    return {
      type: 'CR80Landscape',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Landscape.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.dimensions.h
  ) {
    return {
      type: 'CR80Portrait',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.CR80Portrait.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.dimensions.h
  ) {
    return {
      type: 'XXLPortrait',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.XXLPortrait.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.dimensions.h
  ) {
    return {
      type: 'T4x3',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T4x3.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.dimensions.h
  ) {
    return {
      type: 'T4x5',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T4x5.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.dimensions.h
  ) {
    return {
      type: 'T4x10',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T4x10.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.dimensions.h
  ) {
    return {
      type: 'T4x11',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T4x11.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.dimensions.h
  ) {
    return {
      type: 'T4x12',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T4x12.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.dimensions.h
  ) {
    return {
      type: 'T85x11',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T85x11.fanfold,
    }
  } else if (
    store.width == TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.dimensions.w &&
    store.height == TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.dimensions.h
  ) {
    return {
      type: 'T11x85',
      dimensions: TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.dimensions,
      guideline: TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.guideline,
      notches: TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.notches,
      fanfold: TEMPLATE_GUIDELINE_DIMENSIONS.T11x85.fanfold,
    }
  } else {
    return null
  }
}

export const getTemplateDimensions = (templateType: TemplateType): string | undefined => {
  const dpi = 300
  const widthInches = templateType.Width / dpi
  const heightInches = templateType.Height / dpi

  const widthMm = (templateType.Width / dpi) * 25.4
  const heightMm = (templateType.Height / dpi) * 25.4

  return `${widthInches.toFixed(1)}" × ${heightInches.toFixed(1)}" (${Math.round(widthMm)}mm × ${Math.round(
    heightMm
  )}mm)`
}

export function calculateDaysLeftForTrial(planStartDate: Date): number {
  const startDate = dayjs(planStartDate)

  const trialEndDate = startDate.add(FREE_TRIAL_DAYS, 'day')

  const currentDate = dayjs()

  const daysLeft = trialEndDate.diff(currentDate, 'day')

  return daysLeft > 0 ? daysLeft : 0
}

export function yyyyMmDdCheck(date: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(date)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isInputNumeric(input: any): boolean {
  let result = true
  if (input !== undefined && input !== null && input !== 0) {
    if (typeof input === 'string') {
      try {
        input = parseFloat(input)
        result = !isNaN(parseFloat(input))
      } catch {
        result = false
      }
    } else if (typeof input === 'number') {
      result = !isNaN(Number(input))
    } else if (typeof input !== 'number') {
      result = false
    } else if (isNaN(input)) {
      result = false
    }
  } else {
    return false
  }
  return result
}

export function generateGUID(): string {
  const randomHex = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)

  return `${randomHex()}${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}${randomHex()}${randomHex()}`
}

export function getFileNameFromUrl(url: string): string {
  return url.split('/').pop() || ''
}

export function calculateShippingDate(): Date {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const cutoffTime = dayjs().tz(SHIPPING_TZ_LOCALE).startOf('day').add(SHIPPING_CUTOFF_TIME_EST, 'hours')
  const cutoffHour = cutoffTime.hour()
  const cutoff = new Date(new Date().toLocaleString(SHIPPING_CUTOFF_LOCALE, { timeZone: `${SHIPPING_TZ_LOCALE}` }))

  if (cutoff.getHours() < cutoffHour) {
    cutoff.setDate(cutoff.getDate() + 1)
  } else {
    cutoff.setDate(cutoff.getDate() + 2)
  }

  // if ordered on a sunday, add a day
  if (cutoff.getDay() === 0) {
    cutoff.setDate(cutoff.getDate() + 1)
  }

  return cutoff
}

export function flashContent(element: HTMLElement, resetColor: string): void {
  const originalBackgroundColor = element.style.backgroundColor
  element.style.backgroundColor = '#e1ffe0'
  setTimeout(() => {
    element.style.backgroundColor = (resetColor?.length || 0) > 0 ? resetColor : originalBackgroundColor
  }, 750)
}

export function millisecondsToHoursMinutes(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const formattedTime = `${hours} hours and ${minutes} minutes`
  return formattedTime
}

export const isValidImageUrl = (url: string): boolean => {
  // starts with http(s)
  const webUri = /^(http|https):\/\/[^ "]+$/
  if (!webUri.test(url)) {
    return false
  }
  // contains image extensions
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp']
  return imageExtensions.some((ext) => url.includes(ext))
}

export function base64ToBlob(base64: string): Blob {
  const byteString = atob(base64.split(',')[1])
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export function removeNonAlphanumericsExceptHyphenUnderscorePeriod(str: string): string {
  return str.replace(/[^a-zA-Z0-9-_.]/g, '')
}

export function calculatePrintJobCost(
  recordCount: number,
  blankStock: number,
  templateSelectedTypeId: number,
  costPerCr80: number,
  costPerUnit: number
): number {
  return (
    (recordCount + blankStock) *
    ((templateSelectedTypeId == TemplateTypeEnum.Cr80Landscape ||
    templateSelectedTypeId == TemplateTypeEnum.Cr80Portrait
      ? costPerCr80
      : costPerUnit ?? 0) ?? 0)
  )
}

//TODO: check to see that this is the best regex to be using for this
export const removeDiacritics = (text: string): string => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function onlyNumbers(e: React.KeyboardEvent<HTMLInputElement>): void {
  const allowedKeys = ['Enter', 'Tab', 'Backspace', 'ArrowLeft', 'ArrowRight']

  if (!(allowedKeys.includes(e.key) || (e.key >= '0' && e.key <= '9'))) {
    e.preventDefault()
  }
}

export function findFunctionOverrideDelimiter(input: string): string | null {
  const delimiterResult = input.match(/\}\s*(.*?)\s*\{/)
  return delimiterResult ? delimiterResult[1] : null
}

export function getCustomQrValue(field: FieldSet, record: Record<string, string>): string | undefined {
  if (field.TypeId == FieldType.QRCode) {
    if (field.OverrideFunction && field.OverrideFunction.length > 0) {
      //get all the fields that are in the override function and replace the placeholders with the actual values
      const delimiter = findFunctionOverrideDelimiter(field.OverrideFunction) ?? ''
      const fields = delimiter ? field.OverrideFunction.split(delimiter) : [field.OverrideFunction]

      const qrValue = fields
        .map((fieldData) => record[fieldData.replace('{', '').replace('}', '')] ?? '')
        .join(delimiter)
      return qrValue
    }
  } else {
    return ''
  }
}

export function createUrl(
  baseUrl: string,
  params: Record<string, string | number | boolean | undefined | null>
): string {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      searchParams.append(key, String(value))
    }
  })

  const queryString = searchParams.toString()
  return queryString ? `${baseUrl}?${queryString}` : baseUrl
}

export function getContrastingTextColor(hexColor: string): string {
  const r = parseInt(hexColor.substr(1, 2), 16)
  const g = parseInt(hexColor.substr(3, 2), 16)
  const b = parseInt(hexColor.substr(5, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#000000' : '#ffffff'
}

export function generateReferenceId(): string {
  const now = new Date()
  const randomSuffix = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, '0')
  return (
    `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now
      .getDate()
      .toString()
      .padStart(2, '0')}-` +
    `${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now
      .getSeconds()
      .toString()
      .padStart(2, '0')}-` +
    randomSuffix
  )
}

export function isValidObject(obj: Record<string, unknown> | null | undefined): boolean {
  return obj !== null && obj !== undefined && Object.keys(obj).length !== 0
}

export function isKioskMode(kioskKey?: string): boolean {
  return kioskKey !== undefined && kioskKey !== null && kioskKey.trim() !== ''
}

export const handlePrint = (contentId: string, title: string): void => {
  const printContent = document.getElementById('print-section')
  if (!printContent) return

  const printFrame = document.createElement('iframe')
  printFrame.style.position = 'absolute'
  printFrame.style.width = '0'
  printFrame.style.height = '0'
  printFrame.style.border = 'none'
  document.body.appendChild(printFrame)

  const frameDoc = printFrame.contentDocument || printFrame.contentWindow?.document
  if (!frameDoc) return

  // Copy styles from the main document to the iframe
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('\n')
      } catch (e) {
        return ''
      }
    })
    .join('\n')

  const html = frameDoc.createElement('html')
  const head = frameDoc.createElement('head')
  const body = frameDoc.createElement('body')

  const titleElement = frameDoc.createElement('title')
  titleElement.textContent = title
  head.appendChild(titleElement)

  const styleElement = frameDoc.createElement('style')
  styleElement.textContent = styles
  head.appendChild(styleElement)

  const clonedContent = printContent.cloneNode(true) as HTMLElement

  const reportTitle = frameDoc.createElement('h1')
  reportTitle.textContent = title
  reportTitle.classList.add('print-title')
  body.appendChild(reportTitle)

  clonedContent.classList.add(contentId)
  body.appendChild(clonedContent)

  html.appendChild(head)
  html.appendChild(body)
  frameDoc.documentElement.replaceWith(html)

  setTimeout(() => {
    printFrame.contentWindow?.print()
    printFrame.contentWindow?.addEventListener('afterprint', () => {
      document.body.removeChild(printFrame)
    })
  }, 500)
}

export const handleExportCsv = (columns: string[], dataSource: unknown[], fileName: string): void => {
  const BOM = '\uFEFF'
  const csv =
    BOM +
    Papa.unparse([columns, ...dataSource], {
      header: true,
      quotes: true,
    })

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const linkElement = document.createElement('a')
  linkElement.href = url
  linkElement.download = `${fileName}.csv`
  document.body.appendChild(linkElement)
  linkElement.click()
  document.body.removeChild(linkElement)
  URL.revokeObjectURL(url)
}
